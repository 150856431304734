import React from 'react'
import Container from '../Container'
import { css } from 'styled-components'
import { colors, space, mediaQueries } from '../../utils/tokens'


import PrintAWorldLogo from '../../assets/logo/printaworld-logo-black.svg'
import { Link } from 'gatsby'

const Footer = (props) => {
  return (
    <footer
      css={css({
        backgroundColor: colors.base,
        height: `400px`,
        display: `flex`,
        alignItems: `center`,
        width: `100%`,
      })}
    >
      <div
        css={css({
          padding: `0 ${space[5]}px`,
          [mediaQueries.xxl]: {
            padding: `0 ${space[7]}px`,
            width: `100%`,
          },
        })}
      >
        <Container>
          <div
            css={css({
              [mediaQueries.xxl]: {
                display: `flex`,
                width: `100%`,
                justifyContent: `space-between`,
              }
            })}
          >
            <Link to="/">
              <PrintAWorldLogo
                css={css({
                  height: `48px`,
                  fill: colors.white,
                })}
              />
            </Link>
            <div
              css={css({
                display: `grid`,
                gridGap: `${space[3]}px`,
              })}
            >
              <Link to="/sales-policy/">
                <span
                  css={css({
                    color: colors.white,
                  })}
                >
                  Sales policy
                </span>
              </Link>
              <Link to="/confidential-policy/">
                <span
                  css={css({
                    color: colors.white,
                  })}
                >
                  Confidential policy
                </span>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer