import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'

import { space, breakpoints, colors, fixedHeights } from '../../utils/tokens'

import PrintAWorldLogo from '../../assets/logo/printaworld-logo-black.svg'

const Header = ({
  dark,
  className
}) => {
  return (
    <header
      css={css({
        height: fixedHeights.header,
        width: `100%`,
        position: `absolute`,
        zIndex: 1,
      })}
      className={className}
    >
      <div
        css={css({
          maxWidth: breakpoints.xxl,
          margin: `0 auto`,
          display: `flex`,
          alignItems: `center`,
          height: `100%`,
          padding: `0 ${space[4]}px`,
        })}
      >
        <Link to="/">
          <PrintAWorldLogo
            css={css({
              height: `${space[4]}px`,
              fill: dark ? colors.black : colors.white,
            })}
          />
        </Link>
      </div>
    </header>
  )
}

export default Header