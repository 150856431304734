import React from 'react'
import { css } from 'styled-components'

import {
  mediaQueries,
  palette,
  breakpoints,
  space,
  fixedHeights
} from '../../../utils/tokens'

import Layout from '../../../components/CampaignLandingPage/CampaignLayout'
import Header from '../../../components/CampaignLandingPage/Header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const CustomQuoteLayout = ({
  children
}) => {
  const { contentfulAsset } = useStaticQuery(
    graphql`
      query {
        contentfulAsset(title: { eq: "3d printed wallstreetbets" }) {
          gatsbyImageData
          title
        }
      }
    `
  )

  return (
    <Layout hideFooter>
      <Header
        dark
        css={css({
          position: `initial`,
          borderBottom: `1px solid ${palette.grey[30]}`,
        })}
      />
      <main>
        <div
          css={css({
            height: `calc(100vh - ${fixedHeights.header})`,
            [mediaQueries.xl]: {
              display: `grid`,
              gridTemplateColumns: `5fr 7fr`,
              height: `calc(100vh - ${fixedHeights.header})`,
            },
          })}
        >
          {contentfulAsset ? (
            <GatsbyImage image={contentfulAsset.gatsbyImageData} alt={contentfulAsset.title} />
          ) : (
            <div
              css={css({
                backgroundColor: palette.grey[20],
                overflow: `hidden`,
                position: `relative`,
              })}
            />
          )}

          <section
            css={css({
              position: `relative`,
              overflow: `hidden`,
              height: `100%`,
              [mediaQueries.xxl]: {
                padding: 0,
              },
            })}
          >
            <div
              css={css({
                height: `100%`,
                overflow: `scroll`,
              })}
            >
              <div
                css={css({
                  height: `auto`,
                  padding: `${space[5]}px ${space[3]}px`,
                  paddingBottom: `${space[6]}px`,
                  [mediaQueries.xxl]: {
                    padding: 0,
                    maxWidth: breakpoints.sm,
                    marginLeft: `${space[5]}px`,
                    paddingTop: `${space[5]}px`,
                    paddingBottom: `${space[7]}px`,
                  },
                })}
              >
                {children}
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default CustomQuoteLayout
