import React from 'react'
import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'

const Success = () => {
  return (
    <CustomQuoteLayout>
      <h1>We received your inquiry!</h1>
    </CustomQuoteLayout>
  )
}

export default Success