import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '../../styles/global'

import config from '../../utils/siteConfig'
import theme from '../../styles/theme'
import Footer from './Footer'

const CampaignLayout = ({ isIndexed, hideFooter, children }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={config.siteDescription} />
        <meta property="og:title" content={config.siteTitle} />
        <meta property="og:url" content={config.siteUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={config.siteTitle} />
        {isIndexed ? null : <meta name="robots" content="noindex" />}
        <link
          rel="preconnect dns-prefetch"
          href="https://marketingplatform.google.com"
        />
        <link rel="preconnect dns-prefetch" href="https://www.google.com" />
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <div className="siteContent">{children}</div>
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        {hideFooter ? null : <Footer />}
      </ThemeProvider>
    </div>
  )
}

export default CampaignLayout

CampaignLayout.propTypes = {
  isIndexed: PropTypes.bool,
}

CampaignLayout.defaultProps = {
  isIndexed: false,
}